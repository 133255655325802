<template>
  <div
    id="customer_order_list_bootstrap"
    class="customer_order_list wrapper"
  >
    <div v-if="totalCount > 0">
      <table class="orders-table w-100">
        <thead class="table-header">
          <tr>
            <th class="order-number">
              {{ $t('orders.order_number') }}
            </th>
            <th class="table-event">
              {{ $t('orders.event') }}
            </th>
            <th class="date">
              {{ $t('orders.order_date') }}
            </th>
            <th class="show">
              {{ $t('orders.show') }}
            </th>
          </tr>
        </thead>
    
        <tbody class="orders">
          <tr
            v-for="order in orders"
            :key="order.id"
            class="order"
          >
            <td>
              <div class="order-number d-flex">
                <strong class="key">
                  {{ $t('orders.order_number') }}
                </strong>

                <div class="value">
                  {{ order.opportunity_id }}
                </div>
              </div>
            </td>
        
            <td>
              <div class="table-event d-flex">
                <strong class="key">
                  {{ $t('orders.event') }}
                </strong>

                <div class="value">
                  {{ order.event_name }}
                </div>
              </div>
            </td>

            <td>
              <div class="date d-flex">
                <strong class="key">
                  {{ $t('orders.order_date') }}
                </strong>

                <div class="value">
                  {{ formatDate(order.created_at) }}
                </div>
              </div>
            </td>
            <td class="view-order-button d-flex">
              <a
                :href="orderLink(order)"
                class="btn btn-primary"
              >
                {{ $t('orders.view') }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  
    <div v-if="totalCount == 0">
      <div class="message">
        {{ $t('orders.no_orders') }}
      </div>
  
      <div class="cta">
        <a
          class="btn btn-secondary"
          href="/packages"
        >
          {{ $t('orders.continue_shopping') }}
        </a>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      currentPage: 1,
      totalCount: -1,
      totalPages: 1,
      orders: []
    }
  },
  
  created() {
    this.fetchOrders()
  },
  
  methods: {
    fetchOrders() {
      return fetch(`/oa/orders?page=${this.currentPage}`, {
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      }).then((response) => {
        if (response.status == 200) {
          return response.json().then((body) => {
            this.currentPage = body.current_page
            // this.totalCount = body.total_count
            this.totalPages = body.total_pages
            this.filterOrders(body.orders)
          })
        } else {
          this.totalCount = 0
        }
      }).catch(() => {
        this.totalCount = 0
      })
    },
  
    filterOrders(orders) {
      // TODO: Figure out how to handle H4H CUG orders
      this.orders = orders.filter(order => { return order.h4h_cug != true })
      this.totalCount = this.orders.length
    },
  
    formatDate(date) {
      return new Date(date).
        toLocaleDateString(window.locale, { year: 'numeric', month: 'long', day: 'numeric' })
    },
  
    orderLink(order) {
      if (order.opportunity_id) {
        return `/customer/experiences/${order.opportunity_id_full}`
      } else {
        return `/customer/orders/${order.id}`
      }
    },
  }
}
</script>
  